import React from "react"
import { render } from "react-dom";
import { Stage, Layer, Circle, Path, Text } from 'react-konva'
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel, VictoryLegend, VictoryScatter } from "victory";
import { Slider } from '@material-ui/core'
import VisibilityDetector from "../utils/VisibilityDetector"

import "./FallingEarthSimulator.css"

const EARTH_MASS = 5.972 * Math.pow(10, 24);
const EARTH_RADIUS = 6.371 * Math.pow(10, 6);
const EARTH_ROTATIONAL_VELOCITY = 2*Math.PI/(23*60*60 + 56*60 + 4);
const TIME_STEP = 40;
const TIME_SCALE = 700; // seconds per second
const G = 6.67 * Math.pow(10, -11);
const MAX_FRAMES = 1000;
const FRAME_FREQ = 3;

const ESCAPE_POTENTIAL = 3/2 * G * EARTH_MASS / EARTH_RADIUS;

const MAX_GRAPH_WINDOW = 150;
const MIN_GRAPH_TIME = 10;
const ENERGY_SCALER = .000001;

const SPLOTCHES = [];
for (var i = 0; i < 25; i++) {
  var r1 = Math.pow(Math.random(), .5);
  var r2 = Math.min((1 - r1)*Math.random()*.6, .3);
  SPLOTCHES.push([Math.random()*2*Math.PI, r1, r2, i]);
}


function get_potential_energy(position) {
  const r_squared = Math.pow(position[0], 2) + Math.pow(position[1], 2);
  const r = Math.pow(r_squared, .5);
  if (r <= EARTH_RADIUS) {
    return 1/2 * G * EARTH_MASS / Math.pow(EARTH_RADIUS, 3) * r_squared * ENERGY_SCALER;
    //return (POTENTIAL_AT_SURFACE - 1/2 * G * EARTH_MASS / Math.pow(EARTH_RADIUS, 3) * (Math.pow(EARTH_RADIUS, 2) - r_squared)) * ENERGY_SCALER;
  } else {
    return (ESCAPE_POTENTIAL - G * EARTH_MASS / r) * ENERGY_SCALER;
  }
}

function get_kinetic_energy(velocity) {
  return 1/2 * 1 * (Math.pow(velocity[0], 2) + Math.pow(velocity[1], 2)) * ENERGY_SCALER;
}


export default class FallingEarthSimulator extends React.Component {

  constructor(props) {
    super(props);
    this.interactivity = props.interactivity == undefined ? true : props.interactivity === 'true';
    this.energy_graph = props.energy_graph == undefined ? false : props.energy_graph === 'true';
    this.tunnel_show = props.tunnel == undefined ? true : props.tunnel === 'true';
    this.max_frames = props.max_frames == undefined ? MAX_FRAMES : props.interactivity === 'true';
    this.screen_size = props.size == undefined ? 400 : parseInt(props.size);
    this.low_range = props.low_range == undefined ? 0 : parseInt(props.low_range);
    this.high_range = props.high_range == undefined ? 30 : parseInt(props.high_range);
    const vel_scaler = props.rotational_vel == undefined ? 1 : parseInt(props.rotational_vel);
    const initial_velocity = [vel_scaler*EARTH_ROTATIONAL_VELOCITY*EARTH_RADIUS, 0];
    const initial_potential = get_potential_energy([0, EARTH_RADIUS]);
    const initial_kinetic = get_kinetic_energy(initial_velocity);
    this.state = { running: ! this.interactivity,
                  zoom: 150/EARTH_RADIUS,
                  turns_elapsed: 0,
                  massScaler: 1,
                  radiusScaler: 1,
                  rotationalVelocityScaler: vel_scaler,
                  angle: 3 * Math.PI/2,
                  position: [0, -1*EARTH_RADIUS],
                  velocity: initial_velocity,
                  max_distance: EARTH_RADIUS,
                  true_path: [],
                  relative_path: [],
                  potential_energy_data: [{x: 0, y: initial_potential}],
                  kinetic_energy_data: [{x: 0, y: initial_kinetic}],
                  total_energy: initial_kinetic + initial_potential,
                  is_visible: false,
                };
    this.setRun = this.setRun.bind(this);
    //this.run = this.run.bind(this);
    this.setRotationalVelocityScaler = this.setRotationalVelocityScaler.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
  }

  reset() {
    this.setState(function(state, props) {
      var radius = EARTH_RADIUS*state.radiusScaler;
      var rotational_velocity = EARTH_ROTATIONAL_VELOCITY*state.rotationalVelocityScaler;

      var new_position = this.get_point_from_angle(state.angle, radius);
      var new_velocity = [rotational_velocity*radius, 0];
      const initial_potential = get_potential_energy(new_position);
      const initial_kinetic = get_kinetic_energy(new_velocity);
      return {
        running: false,
        zoom: 150/EARTH_RADIUS,
        turns_elapsed: 0,
        position: new_position,
        max_distance: radius,
        true_path: [],
        relative_path: [],
        velocity: new_velocity,
        potential_energy_data: [{x: 0, y: initial_potential}],
        kinetic_energy_data: [{x: 0, y: initial_kinetic}],
        total_energy: initial_kinetic + initial_potential,
      };
    })
  }

  shouldComponentUpdate() {
    return this.state.is_visible;
  }

  setVisibility(visibility) {
    const prior_visibility = this.state.is_visible;
    if (visibility && ! prior_visibility) {
      const interval_id = setInterval(this.run.bind(this), TIME_STEP);
      this.setState({ is_visible: true, interval_id })
    } else if (! visibility && prior_visibility) {
      clearInterval(this.state.interval_id);
      this.setState({ is_visible: false })
    }
  }

  setRotationalVelocityScaler(event, value) {
    this.setState(function(state, props) {
      return { rotationalVelocityScaler: value };
    },
    this.reset);
  }

  get_internal_mass(planet_mass, planet_radius, inner_radius) {
    var volume_ratio = Math.pow(inner_radius, 3)/Math.pow(planet_radius, 3);
    return volume_ratio*planet_mass;
  }

  get_absolute_acceleration(distance, planet_mass, planet_radius) {
    if (distance > planet_radius) {
      return G*planet_mass/Math.pow(distance, 2);
    } else {
      var internal_mass = this.get_internal_mass(planet_mass, planet_radius, distance);
      return G*internal_mass/Math.pow(distance, 2);
    }
  }

  get_distance(position) {
    return Math.sqrt(Math.pow(position[0], 2) + Math.pow(position[1], 2));
  }

  get_angle(position) {
    return Math.atan2(position[1], position[0]);
  }

  get_point_from_angle(angle, distance) {
    return [Math.cos(angle)*distance, Math.sin(angle)*distance];
  }

  rotate_point(position, angle) {
    var r = this.get_distance(position);
    var new_angle = this.get_angle(position) + angle;
    return this.get_point_from_angle(new_angle, r);
  }

  get_vector_acceleration(position, planet_mass, planet_radius) {
    var r = this.get_distance(position);
    var absolute_acceleration = this.get_absolute_acceleration(r, planet_mass, planet_radius);
    return [-1*position[0]*absolute_acceleration/r, -1*position[1]*absolute_acceleration/r];
  }

  update_velocity(position, velocity, planet_mass, planet_radius, time_step) {
    var vector_acceleration = this.get_vector_acceleration(position, planet_mass, planet_radius);
    return [velocity[0] + vector_acceleration[0]*time_step, velocity[1] + vector_acceleration[1]*time_step];
  }

  update_position(position, velocity, time_step) {
    return [position[0] + velocity[0]*time_step, position[1] + velocity[1]*time_step];
  }

  update_angle(angle, rotational_velocity, time_step) {
    return angle + rotational_velocity*time_step;
  }

  run_step(time_step) {
    this.setState(function(state, props) {
      var planet_mass = EARTH_MASS*state.massScaler;
      var planet_radius = EARTH_RADIUS*state.radiusScaler;
      var planet_rotational_velocity = EARTH_ROTATIONAL_VELOCITY*state.rotationalVelocityScaler;

      var new_angle = this.update_angle(state.angle, planet_rotational_velocity, time_step);
      var new_velocity, new_position, new_turns_elapsed;
      if (state.running) {
        new_velocity = this.update_velocity(state.position, state.velocity, planet_mass, planet_radius, time_step);
        new_position = this.update_position(state.position, new_velocity, time_step);
        new_turns_elapsed = state.turns_elapsed + 1;
      } else {
        new_position = this.get_point_from_angle(new_angle, planet_radius);
        new_velocity = this.get_point_from_angle(new_angle + Math.PI/2, planet_rotational_velocity*planet_radius);
        new_turns_elapsed = state.turns_elapsed;
      }
      var new_max_distance = Math.max(this.get_distance(new_position), state.max_distance);
      var new_zoom = Math.min(150/new_max_distance, state.zoom);
      const kinetic_energy = get_kinetic_energy(new_velocity);
      const potential_energy = get_potential_energy(new_position);
      const minutes_elapsed = new_turns_elapsed * time_step / 60;
      var state_update = {
        position: new_position,
        velocity: new_velocity,
        angle: new_angle,
        turns_elapsed: new_turns_elapsed,
        max_distance: new_max_distance,
        zoom: new_zoom,
      }
      if (state.running) {
        if (state.turns_elapsed % FRAME_FREQ === 0) {
          state_update.true_path = state.true_path.concat([state.position]);
          state_update.relative_path = state.relative_path.concat([this.rotate_point(state.position, -1*state.angle)])
          if (state_update.true_path.length > this.max_frames) {
            state_update.true_path.shift();
            state_update.relative_path.shift();
          }
        }
        state_update.potential_energy_data = state.potential_energy_data.concat({x: minutes_elapsed, y: potential_energy});
        state_update.kinetic_energy_data = state.kinetic_energy_data.concat({x: minutes_elapsed, y: kinetic_energy});
        if (minutes_elapsed - MAX_GRAPH_WINDOW > 0) {
          state_update.potential_energy_data.shift();
          state_update.kinetic_energy_data.shift();
        }
      }
      return state_update
    })
  }

  setRun() {
    if (this.state.running) {
      this.reset();
    } else {
      this.setState({ running: true })
    }
  }

  run() {
    this.run_step(TIME_STEP*TIME_SCALE*.001);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  vis_planet_radius() {
    return EARTH_RADIUS*this.state.radiusScaler*this.state.zoom;
  }

  scale_and_shift(value) {
    return value*this.state.zoom + this.screen_size/2;
  }

  vis_position_x() {
    return this.scale_and_shift(this.state.position[0]);
  }

  vis_position_y() {
    return this.scale_and_shift(this.state.position[1]);
  }

  vis_path(path) {
    var point_path = path.map(pos => [this.scale_and_shift(pos[0]), this.scale_and_shift(pos[1])]);
    var string_point_path = point_path.map(pos => String(pos[0]) + " " + String(pos[1]));
    return "M" + string_point_path.join("L");
  }

  vis_true_path() {
    var path = this.state.true_path.concat([this.state.position]);
    return this.vis_path(path);
  }

  vis_relative_path() {
    var path = this.state.relative_path.map(pos => this.rotate_point(pos, this.state.angle));
    return this.vis_path(path.concat([this.state.position]));
  }

  render() {
    let tunnel = <></>;
    let text = <></>;
    let graph = <></>;
    const time = this.state.turns_elapsed*TIME_STEP*TIME_SCALE*.001/60;
    if (this.tunnel_show) {
      tunnel = <Path data={ this.vis_relative_path() } stroke="#2e2e2e" strokeWidth={ 2 }/>;
    }
    if (this.interactivity) {
      text = <Text x={0} y={0} text={"Minutes Elapsed: " + String(Math.round(time))} fontSize={ 15 }/>
    }
    if (this.energy_graph) {
      const x_hi = time + MIN_GRAPH_TIME; //Math.max(MIN_GRAPH_TIME, time);
      const x_lo = Math.max(0, x_hi - MAX_GRAPH_WINDOW);
      const y_hi = this.state.total_energy * 1.15;
      graph = 
      <div style={{width: this.screen_size}}>
        <VictoryChart width={this.screen_size} height={this.screen_size} domain={{y: [0, y_hi], x: [x_lo, x_hi] }}>
          <VictoryLabel text="Oscillating Energies" x={'50%'} y={30} textAnchor="middle" style={{
            fontSize: 20 }}/>
          <VictoryAxis dependentAxis label="Energy (mega-joules)" style={{
            axisLabel: {fontSize: 15, padding: 30, angle: -90},
            tickLabels: {fontSize: 15, padding: 10, angle: -90} }}/>
          <VictoryAxis label="Time (minutes)" style={{
            axisLabel: {fontSize: 15, padding: 30},
            tickLabels: {fontSize: 15, padding: 5} }} />
          <VictoryLine data={ this.state.potential_energy_data }
            style={{ data: { stroke: "#800000", strokeWidth: 2 }}}/>
          <VictoryScatter
            symbol="circle"
            size={4}
            style={{ data: { fill: "#800000" }}}
            data={ this.state.potential_energy_data.slice(-1) }
          />
          <VictoryLine data={ this.state.kinetic_energy_data }
            style={{ data: { stroke: "#008000", strokeWidth: 2 }}}/>
          <VictoryScatter
            symbol="circle"
            size={4}
            style={{ data: { fill: "#008000" }}}
            data={ this.state.kinetic_energy_data.slice(-1) }
          />
          <VictoryLegend x={70} y={50}
            orientation="horizontal"
            gutter={5}
            style={{ border: { stroke: "black" }, title: {fontSize: 12 } }}
            data={[
              { name: "Potential Energy", symbol: { fill: "#800000"} },
              { name: "Kinetic Energy", symbol: { fill: "#008000" } },
            ]}
          />
        </VictoryChart>
      </div>
    }
    return (
      <>
      <div style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
        <VisibilityDetector isVisible={this.setVisibility}></VisibilityDetector>
        <Stage width={ this.screen_size } height={ this.screen_size }>
          <Layer>
            <Circle x={ this.screen_size/2 } y={ this.screen_size/2 } radius={ this.vis_planet_radius() } fill="green" />
            {SPLOTCHES.map((splotch) => (
              <Circle
                key={ splotch[3] }
                x={ this.get_point_from_angle(splotch[0] + this.state.angle, splotch[1]*this.vis_planet_radius())[0] + this.screen_size/2 }
                y={ this.get_point_from_angle(splotch[0] + this.state.angle, splotch[1]*this.vis_planet_radius())[1] + this.screen_size/2 }
                radius={ this.vis_planet_radius()*splotch[2] }
                fill="#029500"
              />
            ))}
            {tunnel}
            <Path data={ this.vis_true_path() } stroke="#144187" strokeWidth={ 2 }/>
            <Circle x={ this.vis_position_x() } y={ this.vis_position_y() } radius={ 5 } fill="#e63900" />
            {text}
          </Layer>
        </Stage>
        {graph}
      </div>
      <div style={{ justifyContent: 'center', display: this.interactivity ? 'flex' : 'none' }}>
        <button onClick={this.setRun} className='fallingbutton'>{this.state.running ? "RESET" :"FALL!"}</button>
        <div>
          <strong style={{ flex: `1 1` }}>{String(this.state.rotationalVelocityScaler) + "x Earth's Rotational Speed"}</strong>
          <Slider value={ this.state.rotationalVelocityScaler } onChange={this.setRotationalVelocityScaler} min={ this.low_range } max={ this.high_range } style={{maxWidth: 300}}/>
        </div>
      </div>
      </>
    )
  }
}
