import React, { useState } from "react"
import { VictoryChart, VictoryLine } from "victory";
import { Slider } from '@material-ui/core';
import MathJax from 'react-mathjax2';

const DEFAULT_ALPHA_BASE = 15;
const DEFAULT_BETA_BASE = 15;
const NUM_XS = 201;
const EPSILON = .000001;
const BASE = 1.12;
const MULTIPLIER = 1;


function get_beta_point(alpha, beta, x) {
    return Math.pow(x + EPSILON, alpha)*Math.pow(1 - x + EPSILON, beta);
}


function get_point(x, y) {
    return {x, y};
}


function get_beta_distribution(alpha, beta) {
    const indexes = [...Array(NUM_XS).keys()];
    const xs = indexes.map(i => i / (NUM_XS - 1));
    const unscaled_ys = xs.map(x => get_beta_point(alpha, beta, x));
    const sum = unscaled_ys.reduce((a,b) => a + b, 0);
    const integral = sum / NUM_XS;
    const ys = unscaled_ys.map(y => y/integral);
    return indexes.map(i => get_point(xs[i], ys[i]));
}


function log_scale(x) {
    return (MULTIPLIER * Math.pow(BASE, x) - 1).toFixed(2);
}


const BetaDistribution = (props) => {
    const [alphaBase, setAlphaBase] = useState(DEFAULT_ALPHA_BASE);
    const [betaBase, setBetaBase] = useState(DEFAULT_BETA_BASE)
    const alpha = log_scale(alphaBase);
    const beta = log_scale(betaBase);
    const setBetaBaseSymmetric = (base) => {
        if (props.symmetric) {
            setAlphaBase(base);
        }
        setBetaBase(base);
    };
    var math_string;
    if (props.symmetric) {
        math_string = "\\beta = " + String(beta);
    } else {
        math_string = '\\alpha = ' + String(alpha) + ";\\beta = " + String(beta);
    }

    return (
      <>
      <div style={{width: props.width, marginLeft: 'auto', marginRight: 'auto'}}>
            <VictoryChart width={ props.width } height={ props.height }>
              <VictoryLine data={ get_beta_distribution(alpha, beta) }
                domain={{ x: [0, 1], y: [0, 7] }}
                style={{ data: { stroke: "#800000", strokeWidth: 2 }}}/>
            </VictoryChart>
      </div>
      <div style={{ textAlign: 'center' }} >
        <MathJax.Context input="ascii" options={{ messageStyle: "none" }} >
          <MathJax.Node inline>
            { math_string }
          </MathJax.Node>
        </MathJax.Context>
      </div>
      <div style={{ width: props.width, margin: 'auto'}} >
        <div style={ props.symmetric ? {display: 'none'}: {} }>
            <MathJax.Context input="ascii" options={{ messageStyle: "none" }} >
                <MathJax.Node inline style={{float: 'left'}}>{'\\alpha: '}</MathJax.Node>
            </MathJax.Context>
            <Slider value={ alphaBase } min={ 0 } max={ 31 } 
                onChange={ (_, newAlphaBase) => setAlphaBase(newAlphaBase) }
                style={{width: props.width - 30, position: 'absolute', marginTop: 2, marginLeft: 2}}/>
        </div>
        <div>
            <MathJax.Context input="ascii" options={{ messageStyle: "none" }} >
                <MathJax.Node inline>{'\\beta: '}</MathJax.Node>
            </MathJax.Context>
            <Slider value={ betaBase } min={ 0 } max={ 31 }
                onChange={ (_, newBetaBase) => setBetaBaseSymmetric(newBetaBase) }
                style={{width: props.width - 30, position: 'absolute', marginTop: 2, marginLeft: 2}}/>
        </div>
      </div>
      </>
    );
  }

export default BetaDistribution
