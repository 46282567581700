import React from "react";
import { render } from "react-dom";
import { Stage, Layer, Circle } from 'react-konva';
import { VictoryChart, VictoryLine, VictoryScatter } from "victory";
import VisibilitySensor from 'react-visibility-sensor';
import { Slider } from '@material-ui/core';
import MathJax from 'react-mathjax2';

const ALPHA = .09; // guess at alpha
const MAX_X = 9;
const STARTING_X = 5;
const POINT_SIZE = 3;
const WALK_SPEED = 50; // in px/sec
const TIME_STEP = 50; // in milliseconds
const MAX_TIME = 20; // in seconds
const CHANGE_FREQUENCY = 5;
const RELATIVELY_PRIME_OFFSET = 3;
const MOVE_DISTANCE = WALK_SPEED * TIME_STEP * .001;
const COLORS = ['blue', 'green', 'black', 'white', 'orange', 'purple', 'red'];

// Standard Normal variate using Box-Muller transform.
function randn_bm() {
    var u = 0, v = 0;
    while(u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while(v === 0) v = Math.random();
    return Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
}

export default class RandomWalk extends React.Component {
  constructor(props) {
    super(props);
    const points = [];
    const vectors = [];

    this.stageWidth = props.width;
    this.stageHeight = props.height * .7;
    this.stageOffset = props.height * .15

    for (var i = 0; i < STARTING_X; i++) {
      points.push(this.place_point());
      vectors.push(this.get_vector());
    }
    this.state = { 
        x: STARTING_X,
        points: points,
        vectors: vectors,
        time: 0,
        data: [{x: 0, y: 0}, {x: 0, y: 0}],
        num_jumps: 0,
        turn: 0,
    };
    this.setX = this.setX.bind(this);
    this.setTopVisibility = this.setTopVisibility.bind(this);
    this.setBottomVisibility = this.setBottomVisibility.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.run.bind(this), TIME_STEP);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  shouldComponentUpdate() {
    return this.is_visible(this.state.top, this.state.bottom);
  }

  is_visible(top, bottom) {
    return top || top === undefined || bottom || bottom === undefined;
  }

  run() {
    this.run_step();
  }

  run_step() {
    if (! this.is_visible(this.state.top, this.state.bottom)) {
      return;
    }
    const new_turn = this.state.turn + 1;
    var current_jumps = this.state.num_jumps;
    var new_jumps = 0;
    var new_time = this.state.time + TIME_STEP*.001;
    var new_data = this.state.data.slice(0, -1);
    if (new_time > MAX_TIME) {
      new_time = 0;
      new_data = [{x: 0, y: 0}];
      current_jumps = 0;
    }
    const new_points = [];
    const new_vectors = [];
    for (var i = 0; i < this.state.x; i++) { // move points and update vectors
      var current_point = this.state.points[i];
      var current_vector = this.state.vectors[i];
      if (! this.is_point_on_screen(current_point)) {
        new_points.push(this.place_point());
        new_vectors.push(this.get_vector());
        new_jumps++;
      } else {
        new_points.push(this.move_point(current_point, current_vector));
        if (this.state.turn % CHANGE_FREQUENCY === i * RELATIVELY_PRIME_OFFSET % CHANGE_FREQUENCY) {
          // i % makes them not all change at once
          new_vectors.push(this.get_vector());
        } else {
          new_vectors.push(current_vector);
        }
      }
    }
    const new_num_jumps = current_jumps + new_jumps;
    new_data.push({x: new_time, y: current_jumps});
    if (new_jumps > 0) {
      new_data.push({x: new_time, y: new_num_jumps});
      new_data.push({x: new_time, y: new_num_jumps});
    }
    this.setState({
        points: new_points,
        vectors: new_vectors,
        time: new_time,
        data: new_data,
        num_jumps: new_num_jumps,
        turn: new_turn,
    });
  }

  reset_data() {
    this.setState({
        time: 0,
        data: [{x: 0, y: 0}, {x: 0, y: 0}],
    })
  }

  get_vector() {
    return [randn_bm()*MOVE_DISTANCE, randn_bm()*MOVE_DISTANCE];
  }

  place_point() {
    const r1 = 2 * Math.random() - 1;
    const r2 = 2 * Math.random() - 1;
    return [
      Math.acos(r1)/Math.PI * this.stageWidth,
      Math.acos(r2)/Math.PI * this.stageHeight
    ]
  }

  is_point_on_screen(point) {
    return point[0] + POINT_SIZE >= 0 && point[0] - POINT_SIZE <= this.stageWidth &&
           point[1] + POINT_SIZE >= 0 && point[1] - POINT_SIZE <= this.stageHeight;
  }

  move_point(point, vector) {
    return [point[0] + vector[0], point[1] + vector[1]];
  }

  setX(_, new_x) {
    if (new_x === this.state.x) {
      return;
    }
    var new_points;
    var new_vectors;
    if (new_x < this.state.x) {
      new_points = this.state.points.slice(0, new_x)
      new_vectors = this.state.vectors.slice(0, new_x)
    } else {
      const additional_points = [];
      const additional_vectors = [];
      for (var i = 0; i < new_x - this.state.x; i++) {
        additional_points.push(this.place_point());
        additional_vectors.push(this.get_vector());
      }
      new_points = this.state.points.concat(additional_points);
      new_vectors = this.state.vectors.concat(additional_vectors);
    } 
    const new_time = 0;
    const new_data = [{x: 0, y: 0}, {x: 0, y: 0}];
    const new_num_jumps = 0;
    this.setState({
        x: new_x,
        points: new_points,
        vectors: new_vectors,
        time: new_time,
        data: new_data,
        num_jumps: new_num_jumps,
    });
  }

  setTopVisibility(isVisible) {
    this.setState({ top: isVisible });
  }

  setBottomVisibility(isVisible) {
    this.setState({ bottom: isVisible });
  }

  render() {
    return (
      <span>
        <VisibilitySensor onChange={ this.setTopVisibility }>
          <p style={{float: 'left', opacity: 0}}>.</p>
        </VisibilitySensor>
        <div style={{ textAlign: 'center' }} >
          <MathJax.Context input="ascii" options={{ messageStyle: "none" }} >
            <MathJax.Node inline>{'\\lambda('+String(this.state.x)+') = \\alpha * '+String(this.state.x)}</MathJax.Node>
          </MathJax.Context>
        </div>
        <div style={{width: '49%', float: 'left', display: 'inline-block'}}>
          <div style={{width: this.stageWidth, backgroundColor: 'lightgrey', border: '1px solid black', float: 'right', marginTop: this.stageOffset}}>
            <Stage width={ this.stageWidth } height={ this.stageHeight }>
                <Layer>
                    {this.state.points.map((point, index) => (
                    <Circle
                        key={ index }
                        x={ point[0] }
                        y={ point[1] }
                        radius={ POINT_SIZE }
                        fill={ COLORS[index%COLORS.length] }
                    />
                    ))}
                </Layer>
            </Stage>
          </div>
        </div>
        <div style={{width: '49%', float: 'left'}}>
          <div style={{width: this.props.width, float: 'left'}}>
            <VictoryChart width={this.props.width} height={this.props.height}>
              <VictoryLine data={ this.state.data } domain={{ x: [0, MAX_TIME], y: [0, 1.3*MAX_TIME*ALPHA*MAX_X] }}
                style={{ data: { stroke: "#800000", strokeWidth: 2 }}}/>
              <VictoryLine data={ [{ x: 0, y: 0 }, { x: MAX_TIME, y: MAX_TIME*ALPHA*this.state.x}] }
                style={{ data: { strokeDasharray: "5,5", }}}/>
              <VictoryScatter
                  symbol="circle"
                  size={4}
                  style={{ data: { fill: "#800000" }}}
                  data={ this.state.data.slice(-1) }
              />
            </VictoryChart>
          </div>
        </div>
        <div style={{ width: this.props.width, margin: 'auto', clear: 'left', textAlign: 'center' }} >
          <MathJax.Context input="ascii" options={{ messageStyle: "none" }} >
            <MathJax.Node inline>{'x = '+String(this.state.x)}</MathJax.Node>
          </MathJax.Context>
          <VisibilitySensor onChange={ this.setBottomVisibility }>
            <Slider value={ this.state.x } onChange={ this.setX } min={ 1 } max={ MAX_X } marks={ true }/>
          </VisibilitySensor>
        </div>
      </span>
    )
  }
}
