export function children_to_id(children, prefix = "", suffix = "") {
    const strings = children.map(val => {
      if (typeof(val) === "string") {
        return string_to_id(val);
      } else {
        return "math";
      }
    });
    return prefix + strings.join("") + suffix;
}

export function string_to_id(string, prefix = "", suffix = "") {
    const cleaned_string = string.replace(/[^a-zA-Z0-9 ]/g, "");
    const output = prefix + cleaned_string.split(" ").join("_") + suffix;
    return output.toLowerCase();
}