import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css'; // TODO: change this
import "katex/dist/katex.min.css";
import renderMathInElement from "katex/dist/contrib/auto-render.js";
import { string_to_id } from '../utils/GetID.js';

export default class Expandable extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    renderMathInElement(this.myRef.current,
      {delimiters: [
        {left: "$$", right: "$$", display: true},
        {left: "$", right: "$", display: false},
      ]});
  }

  render() {
    return (
      <div ref={this.myRef} style={{clear: 'both'}} id={ string_to_id(this.props.title) }>
        <Accordion allowZeroExpanded={ true }>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                { this.props.title }
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div>
                { this.props.children }
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}

