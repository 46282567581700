import React from "react"
import { Link, graphql } from "gatsby"
import rehypeReact from "rehype-react"
import Img from "gatsby-image"

import { GetBioPictures } from "../hooks/get_bio_pictures.js"

import BetaDistribution from "../components/demos/BetaDistribution"
import BetaCalculator from "../components/demos/BetaCalculator"
import Caption from "../components/article/caption"
import DawnSpeed from "../components/demos/DawnSpeed"
import FallingEarthSimulator from "../components/demos/FallingEarthSimulator"
import FallingLatitude from "../components/map_demos/FallingLatitude"
import FootnoteReference from "../components/article/footnote_reference"
import Globe from "../components/map_demos/Globe"
import GlobeCrossing from "../components/map_demos/GlobeCrossing"
import Seasons from "../components/map_demos/Seasons"
import DelaunayTriangularization from "../components/demos/DelaunayTriangularization"
import Layout from "../components/web/layout"
import Expandable from "../components/article/expandable"
import MapCrossing from "../components/map_demos/MapCrossing"
import MapCrossingGame from "../components/map_demos/MapCrossingGame"
import MVPGame from "../components/demos/MVPGame"
import RandomSnowleopard from "../components/demos/RandomSnowleopard"
import RandomWalk from "../components/demos/RandomWalk"
import ScrollableTable from "../components/article/scrollable_table"
import SEO from "../components/web/seo"
import Subtitle from "../components/article/subtitle"

import "./article.css"

require('katex/dist/katex.css');

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
                "beta-calculator": BetaCalculator,
                "beta-distribution": BetaDistribution,
                "dawn-speed": DawnSpeed,
                "delaunay-triangularization": DelaunayTriangularization,
                "expandable": Expandable,
                "falling-earth-simulator": FallingEarthSimulator,
                "falling-latitude": FallingLatitude,
                "fig-caption": Caption,
                "globe": Globe,
                "globe-crossing": GlobeCrossing,
                "footnote-reference": FootnoteReference,
                "map-crossing": MapCrossing,
                "map-crossing-game": MapCrossingGame,
                "mvp-game": MVPGame,
                "random-snowleopard": RandomSnowleopard,
                "random-walk": RandomWalk,
                "scrollable-table": ScrollableTable,
                "seasons": Seasons,
                h2: Subtitle,
              },
}).Compiler

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { excerpt, frontmatter, htmlAst } = markdownRemark
  const images = GetBioPictures();
  const fb_image = frontmatter.image
      ? frontmatter.image.childImageSharp.resize
      : null
  return (
    <Layout>
    <SEO title={frontmatter.title} image={fb_image} pathname={frontmatter.path} description={excerpt}/>
    <div className="blogpost">
      <h1 className="articletitle">{frontmatter.title}</h1>
      <div className="articleinfo">
        <Link className="biolink" to={'/aboutus'} >
          <Img fixed={images.alex_tiny.childImageSharp.fixed} style={{ borderRadius: 15, margin: 0, padding: 0, width: 30, height: 30 }} />
        </Link>
        <Link className="biolink" to={'/aboutus'} >
          <Img fixed={images.jack_tiny.childImageSharp.fixed} style={{ borderRadius: 15, margin: 0, padding: 0, width: 30, height: 30 }} />
        </Link>
        <h4 className="byline">{ `Alex and Jack Strang - ${frontmatter.date}`} </h4>
      </div>
      {
        renderAst(htmlAst)
      }
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        image: image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
