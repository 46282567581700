import React, { useState } from "react"
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter, VictoryArea, VictoryLabel, VictoryLegend } from "victory";
import { Slider } from '@material-ui/core';

const EARTH_ROTATIONAL_SPEED = 1037;
const TOP_DIST = get_dawn_speed_distribution(1);
const BOTTOM_DIST = get_dawn_speed_distribution(-1);

function get_point(x, y) {
    return {x, y};
}

function get_dawn_speed_distribution(pos) {
    const indexes = [...Array(91).keys()];
    const latitudes = indexes.map(i => (90 - i) * pos);
    const speeds = latitudes.map(l => latitude_to_dawn_speed(l));
    return indexes.map(i => get_point(speeds[i], latitudes[i]))
}

function latitude_to_dawn_speed(latitude) {
    return Math.cos(latitude * Math.PI / 180)*EARTH_ROTATIONAL_SPEED;
}

function dawn_speed_to_latitude(speed) {
    return Math.acos(speed/EARTH_ROTATIONAL_SPEED) * 180 / Math.PI;
}


const DawnSpeed = (props) => {
    const [planeSpeed, setPlaneSpeed] = useState(675);
    const balanced_lat = dawn_speed_to_latitude(planeSpeed);
    return (
      <>
      <div style={{width: props.width, height: props.height + 20, marginLeft: 'auto', marginRight: 'auto'}}>
            <VictoryChart width={ props.width } height={ props.height }>
                <VictoryAxis 
                    offsetY={50}
                    label="Speed of Plane (mph)" 
                    tickValues={[250, 500, 750, 1000]} 
                    style={{
                        axisLabel: {fontSize: 15, padding: 30},
                        grid: { stroke: "#818e99", strokeWidth: 0.4 },
                      }}
                />
                <VictoryAxis dependentAxis 
                    label="Longitude" 
                    tickValues={[-90, -60, -30, 0, 30, 60, 90]} 
                    tickFormat={["-90","-60","-30","0","30","60","90"]}
                    style={{
                        axisLabel: {fontSize: 15, padding: 30, angle: -90},
                        grid: { stroke: "#818e99", strokeWidth: 0.4 },
                      }}
                />
                <VictoryScatter data={[{x: 0, y: 90}]} 
                    size={0} 
                    labels="North Pole" 
                    labelComponent={
                        <VictoryLabel
                          dx={-30} dy={-20}
                          textAnchor="start"
                        />
                      }
                />
                <VictoryScatter data={[{x: 0, y: -90}]} 
                    size={0} 
                    labels="South Pole" 
                    labelComponent={
                        <VictoryLabel
                        dx={-30} dy={20}
                          textAnchor="start"
                        />
                      }
                />
                <VictoryArea data={ [{x: 0 , y: 90, y0: balanced_lat}, {x: EARTH_ROTATIONAL_SPEED*1.1 , y: 90, y0: balanced_lat}] } 
                   style={{ data: { fill: "purple", fillOpacity: 0.4, strokeWidth: 1} }}
                />
                <VictoryArea data={ [{x: 0 , y: -90, y0: -1*balanced_lat}, {x: EARTH_ROTATIONAL_SPEED*1.1 , y: -90, y0: -1*balanced_lat}] }
                    style={{ data: { fill: "purple", fillOpacity: 0.4, strokeWidth: 1} }}
                />
                <VictoryLine data={ TOP_DIST }
                    domain={{ x: [0, EARTH_ROTATIONAL_SPEED * 1.1], y: [-90, 90] }}
                    style={{ data: { stroke: "#800000", strokeWidth: 2 }}}/>
                <VictoryLine data={ BOTTOM_DIST }
                    domain={{ x: [0, EARTH_ROTATIONAL_SPEED * 1.1], y: [-90, 90] }}
                    style={{ data: { stroke: "#800000", strokeWidth: 2 }}}/>
                <VictoryLine data={ [{x: planeSpeed, y: -90}, {x: planeSpeed, y: 90}]}
                    style={{ data: { stroke: "#000080", strokeWidth: 2 }}}/>
                <VictoryScatter
                    symbol="circle"
                    size={4}
                    style={{ data: { fill: "#000080" }}}
                    data={ [{x: planeSpeed, y: balanced_lat},
                        {x: planeSpeed, y: -1 * balanced_lat}] }
                />
                <VictoryScatter 
                    data={[{x: 0, y: balanced_lat}]} 
                    size={0} 
                    style={{ data: { fill: "purple"}}}
                    labels="faster than dawn"
                    labelComponent={
                        <VictoryLabel
                          dx={10}
                          textAnchor="start"
                          verticalAnchor="middle"
                        />
                      }
                />
                <VictoryScatter 
                    data={[{x: 0, y: -balanced_lat}]} 
                    size={0} 
                    style={{ data: { fill: "purple"}}}
                    labels="faster than dawn"
                    labelComponent={
                        <VictoryLabel
                          dx={10}
                          textAnchor="start"
                          verticalAnchor="middle"
                        />
                      }
                />
            </VictoryChart>
            <Slider value={ planeSpeed } min={ 0 } max={ EARTH_ROTATIONAL_SPEED } 
                onChange={ (_, newPlaneSpeed) => setPlaneSpeed(newPlaneSpeed) }
                style={{width: 275, position: 'absolute', marginTop: -20,
                marginLeft: 'auto', marginRight: 'auto', left: 0, right: 25}}/>
        </div>
      </>
    );
  }

export default DawnSpeed
