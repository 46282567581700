import React, { useState } from "react"
import Globe from "./Globe"
import * as THREE from "three";
import { Slider } from '@material-ui/core';
import CoordinatePic from './coordinate_plane_2.png';

const M = 5.972 * Math.pow(10, 24);
const R = 6.371 * Math.pow(10, 6);
const G = 6.67 * Math.pow(10, -11);

const OMEGA_EARTH = 2 * Math.PI / (24 * 60 * 60);
const OMEGA = Math.sqrt(G * M / Math.pow(R, 3));


class FallingLatitudeGlobe extends Globe {
  constructor( props ) {
    super(props);
  }

  add_childclass_objects( scene ) {
    const r = 2 * this.radius;
    const geometry = new THREE.PlaneGeometry( r, r );
    const texture = new THREE.TextureLoader().load( CoordinatePic );
    const pic_material = new THREE.MeshBasicMaterial(
        {
          map: texture,
          side: THREE.DoubleSide,
        } );
    const material = new THREE.MeshBasicMaterial(
      {
        color: 0xffffff,
        side: THREE.DoubleSide,
      } );
    this.plane = new THREE.Mesh( geometry, this.props.show_coords ? pic_material : material );
    this.plane.rotation.x = Math.PI/2;
    this.plane.rotation.z = Math.PI;
    this.plane.rotation.y = Math.PI;
    scene.add( this.plane );

    const sphere_material = new THREE.MeshBasicMaterial( { color: 0xe63900 } );
    const sphere_geometry = new THREE.SphereGeometry( 10, 10, 10 );
    this.capsule = new THREE.Mesh( sphere_geometry, sphere_material);
    scene.add(this.capsule);
    this.capsule.position.z = -1 * this.radius;

    if (this.props.show_path) {
      const path_material = new THREE.MeshBasicMaterial( { color: 0x1a54ab } );
      const path_geometry = this.get_path_geometry(this.master_solution);
      this.path = new THREE.Mesh( path_geometry, path_material);
      scene.add( this.path );
    }
  }

  get_path_geometry() {
    // in the x-y plane
    const v_x = this.radius * OMEGA_EARTH * Math.cos(this.props.fall_latitude * Math.PI / 180);
    const s_x = v_x / OMEGA;

    const angles = Array.from(Array(360).keys());
    const points = angles.map(angle => {
        const rad_angle = angle * Math.PI / 180;
        const x = s_x * Math.sin(rad_angle);
        const y = this.radius * Math.cos(rad_angle);
        return new THREE.Vector3(x, 0, y);
    });
    return new THREE.TubeGeometry(new THREE.CatmullRomCurve3(points), 200, 3);
  }
  
  update_childclass_objects() {
    const raditude = this.props.fall_latitude * Math.PI / 180;
    this.plane.rotation.x = raditude + Math.PI / 2;
    this.capsule.position.z = -1 * Math.cos(raditude) * this.radius;
    this.capsule.position.y = Math.sin(raditude) * this.radius;
    if (this.props.show_path) {
      this.path.geometry = this.get_path_geometry();
      this.path.rotation.x = raditude;
    }
  }
}


const MARKS = [
  {
    value: -45,
    label: '45° South',
  },
  {
    value: 0,
    label: '0°',
  },
  {
    value: 45,
    label: '45° North',
  },
]

const FallingLatitude = (props) => {
    const [latitude, setLatitude] = useState(0);
    const show_path = props.show_path == undefined ? true : props.show_path == 'true';
    const show_coords = props.show_coords == undefined ? false : props.show_coords == 'true';
    return (
      <div style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', display: 'block' }} >
        <FallingLatitudeGlobe
          rotate_earth={ false }
          transparent={ true }
          show_axis={ false }
          show_arctic_circles={ false }
          show_path={ show_path }
          show_coords={ show_coords }
          fall_latitude={ latitude }
          camera_angle={Math.PI/2 * .8}
          opacity={.4}
          can_zoom={ false }
        >
        </FallingLatitudeGlobe>
        <Slider
          value={ latitude } min={ -90 } max={ 90 }
          valueLabelDisplay="auto"
          marks={ MARKS }
          track={ false }
          onChange={ (_, newLatitude) => setLatitude(newLatitude) }
        />
      </div>
    );
  }

export default FallingLatitude