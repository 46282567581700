import VisibilitySensor from 'react-visibility-sensor';
import React from "react"

export default class VisibilityDetector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        top: false,
        bottom: false,
    };
    this.setTopVisibility = this.setTopVisibility.bind(this);
    this.setBottomVisibility = this.setBottomVisibility.bind(this);
  }

  update(old_top, new_top, old_bottom, new_bottom) {
    const prior_visibility = old_top || old_bottom;
    const new_visibility = new_top || new_bottom;
    if (!prior_visibility && new_visibility) {
      this.props.isVisible(true);
    } else if (prior_visibility && !new_visibility) {
      this.props.isVisible(false);
    }
    this.setState({ top: new_top, bottom: new_bottom})
  }

  setTopVisibility(visibility) {
    this.update(this.state.top, visibility, this.state.bottom, this.state.bottom);
  }

  setBottomVisibility(visibility) {
    this.update(this.state.top, this.state.top, this.state.bottom, visibility)
  }

  render() {
    return (
      <>
      <VisibilitySensor onChange={ this.setTopVisibility }>
        <div style={{position: 'absolute', top: 0, width: '1px', height: '1px', opacity: 0, backgroundColor: 'blue'}}></div>
      </VisibilitySensor>
      <VisibilitySensor onChange={ this.setBottomVisibility }>
        <div style={{position: 'absolute', bottom: 0, width: '1px', height: '1px', opacity: 0, backgroundColor: 'red'}}></div>
      </VisibilitySensor>
      </>
    );
  }
}