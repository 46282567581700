import React from "react";
import { render } from "react-dom";
import { Stage, Layer, Circle, Star} from 'react-konva';

const POINT_RADIUS = 5;
const WEIGHT_SCALER = 20;
const EPSILON = .001;
const WEIGHT_ADDER_SLOW = .002;
const WEIGHT_ADDER_FAST = .006;
const WEIGHT_MULT_SLOW = 1;
const WEIGHT_MULT_FAST = 1.01;
const ACCEPTED_RANGE = 10;
const NEAREST_POINT_RANGE = 40;
const TIME_STEP = 20;
const SLOW_TIME = 700

function sum(l) {
  return l.reduce((a, b) => a + b, 0)
}

function normalize(weights) {
  var s = sum(weights);
  return weights.map(w => w/s);
}

function distance(point1, point2) {
  return Math.pow(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2), .5);
}


export default class MVPGame extends React.Component {
  constructor(props) {
    super(props);
    var points_and_weights = this.generate_points_weights();
    this.state = {
      points: points_and_weights[0],
      weights: points_and_weights[1],
      desired_mean: this.generate_desired_mean(points_and_weights[0]),
      clicked_index: 0,
      interval_id: 0,
      time_held: 0,
    };
    this.add_weight_step = this.add_weight_step.bind(this);
    this.add_weight = this.add_weight.bind(this);
    this.stop_weight = this.stop_weight.bind(this);
    
  }

  reset() {
    this.setState(function(state, props) {
      var points_and_weights = this.generate_points_weights();
      return {
          points: points_and_weights[0],
          weights: points_and_weights[1],
          desired_mean: this.generate_desired_mean(points_and_weights[0]),
          clicked_index: 0,
          interval_id: 0,
          time_held: 0,
      };
    });
  }

  generate_points_weights() {
    var points = [];
    var weights = [];
    for (var i = 0; i < this.props.num_points; i++) {
      points.push([Math.random()*this.props.width, Math.random()*this.props.height]);
      weights.push(Math.random()*Math.random());
    }
    return [points, normalize(weights)]
  }

  generate_desired_mean(points) {
    var w = this.props.width;
    var h = this.props.height;
    for (var i = 0; i < 100; i++) { // try enough times
      let mean = [w*.25 + Math.random()*.5*w, h*.25 + Math.random()*.5*h];
      if (Math.min(...points.map(p => distance(p, mean))) < NEAREST_POINT_RANGE) {
        return mean;
      }
    }
    return [w*.25 + Math.random()*.5*w, h*.25 + Math.random()*.5*h];
  }

  scale_by_weights(l) {
    // l is a list, multiply every item in l by the corresponding weight in weights
    var weights = this.state.weights;
    return l.map(function (x, i) {
      return x*weights[i]
    });
  }

  get_mean() {
    var x = sum(this.scale_by_weights(this.state.points.map(p => p[0])));
    var y = sum(this.scale_by_weights(this.state.points.map(p => p[1])));
    return [x, y];
  }

  get_variance() {
    // Alex, is this right?
    var mean = this.get_mean();
    var distances = this.state.points.map(p => distance(p, mean));
    var squared_distances = distances.map(d => d*d);
    var variance = sum(this.scale_by_weights(squared_distances));
    return Math.pow(variance, .5) // return the std-dev so that distances are intuitive
  }

  weight_to_radius(weight) {
    if (weight < EPSILON) {
      return 0;
    }
    return weight*WEIGHT_SCALER + POINT_RADIUS;
  }

  add_weight(e) {
    this.setState({ clicked_index: e.target.attrs.id });
    var interval_id = setInterval(this.add_weight_step, TIME_STEP);
    this.setState({ interval_id: interval_id });
  }

  stop_weight() {
    clearInterval(this.state.interval_id);
    this.setState({ time_held: 0 });
  }

  add_weight_step() {
    var new_weights = this.state.weights.slice();
    var weight_addr = this.state.time_held < SLOW_TIME ? WEIGHT_ADDER_SLOW : WEIGHT_ADDER_FAST
    var weight_mult = this.state.time_held < SLOW_TIME ? WEIGHT_MULT_SLOW : WEIGHT_MULT_FAST

    new_weights[this.state.clicked_index] = new_weights[this.state.clicked_index] * weight_mult + weight_addr;
    var new_time_held = this.state.time_held + TIME_STEP;
    this.setState({ weights: normalize(new_weights), time_held: new_time_held });
  }

  render() {
    return (
      <span>
        <Stage width={ this.props.width } height={ this.props.height }
          onMouseUp={ this.stop_weight } onMouseLeave={ this.stop_weight }>
          <Layer>
            {this.state.weights.map((weight, index) => (
              <Circle
                key={ index }
                id={ index }
                x={ this.state.points[index][0] }
                y={ this.state.points[index][1] }
                radius={ this.weight_to_radius(weight) }
                fill="blue"
              />
            ))}
            <Circle
              x={ this.get_mean()[0] }
              y={ this.get_mean()[1] }
              radius={ this.get_variance() }
              opacity={.25}
              fill="blue"
            />
            <Circle
              x={ this.state.desired_mean[0] }
              y={ this.state.desired_mean[1] }
              radius={ ACCEPTED_RANGE }
              fill="red"
              opacity={ .5 }
            />
            <Star
              x={ this.state.desired_mean[0] }
              y={ this.state.desired_mean[1] }
              numPoints = { 5 }
              innerRadius={ POINT_RADIUS*.66 }
              outerRadius={ POINT_RADIUS }
              fill="red"
            />
            {this.state.points.map((point, index) => (
              <Circle
                key={ index }
                id={ index }
                x={ point[0] }
                y={ point[1] }
                radius={ POINT_RADIUS }
                onMouseDown={ this.add_weight }
                fill="black"
              />
            ))}
            <Circle
              x={ this.get_mean()[0] }
              y={ this.get_mean()[1] }
              radius={ POINT_RADIUS }
              fill="blue"
              stroke="black"
              strokeRadius={ 1 }
            />
          </Layer>
        </Stage>
      </span>
    )
  }
}