import React from "react";
import { render } from "react-dom";
import { Stage, Layer, Circle, Path } from 'react-konva';

import Delaunator from 'delaunator';
import { matrix, det } from 'mathjs';


export default class DelaunayTriangularization extends React.Component {
  constructor(props) {
    super(props);
    var points = [];
    for (var i = 0; i < props.num_points; i++) {
      points.push([Math.random()*props.width, Math.random()*props.height]);
    }
    this.state = { points: points,
                   triangles: this.generate_triangles(points),
                   circles: [],
                   highlighted_triangles: []
                };
  }

  generate_triangles(points) {
    var delaunay = Delaunator.from(points);
    return this.get_delaunay_triangles(delaunay);
  }

  get_delaunay_triangles(delaunay) {
    var d_triangles = delaunay.triangles;
    var triangles = []
    for (var i = 0; i < d_triangles.length; i += 3) {
      triangles.push([d_triangles[i], d_triangles[i + 1], d_triangles[i + 2]]);
    }
    return triangles;
  }

  get_circumcenter(triangle, points) {
    var ax = points[triangle[0]][0];
    var ay = points[triangle[0]][1];
    var bx = points[triangle[1]][0];
    var by = points[triangle[1]][1];
    var cx = points[triangle[2]][0];
    var cy = points[triangle[2]][1];

    var d = 2*(ax*(by - cy) + bx*(cy - ay) + cx*(ay - by));
    var x = 1/d*((ax*ax + ay*ay)*(by - cy) + (bx*bx + by*by)*(cy - ay) + (cx*cx + cy*cy)*(ay - by));
    var y = 1/d*((ax*ax + ay*ay)*(cx - bx) + (bx*bx + by*by)*(ax - cx) + (cx*cx + cy*cy)*(bx - ax));
    return [x, y];
  }

  is_point_in_circumcircle(triangle, points, point) {
    var ax = points[triangle[0]][0];
    var ay = points[triangle[0]][1];
    var bx = points[triangle[1]][0];
    var by = points[triangle[1]][1];
    var cx = points[triangle[2]][0];
    var cy = points[triangle[2]][1];
    var dx = point[0];
    var dy = point[1];
    var dx_2 = dx*dx
    var dy_2 = dy*dy

    var row1 = [ax - dx, ay - dy, ax*ax - dx_2 + ay*ay - dy_2];
    var row2 = [bx - dx, by - dy, bx*bx - dx_2 + by*by - dy_2];
    var row3 = [cx - dx, cy - dy, cx*cx - dx_2 + cy*cy - dy_2];

    var M = matrix([row1, row2, row3])
    return det(M) > 0;
  }

  get_barycentric_coords(triangle, points, point) {
    var x1 = points[triangle[0]][0];
    var y1 = points[triangle[0]][1];
    var x2 = points[triangle[1]][0];
    var y2 = points[triangle[1]][1];
    var x3 = points[triangle[2]][0];
    var y3 = points[triangle[2]][1];
    var x = point[0];
    var y = point[1];
    
    var a = ((y2 - y3)*(x - x3) + (x3 - x2)*(y - y3)) / ((y2 - y3)*(x1 - x3) + (x3 - x2)*(y1 - y3));
    var b = ((y3 - y1)*(x - x3) + (x1 - x3)*(y - y3)) / ((y2 - y3)*(x1 - x3) + (x3 - x2)*(y1 - y3));
    var c = 1 - a - b;
    return [a, b, c]
  }

  is_point_in_triangle(triangle, points, point) {
    var bary = this.get_barycentric_coords(triangle, points, point);
    return ((0 <= bary[0]) && (bary[0] <= 1) && (0 <= bary[1]) && (bary[1] <= 1) && (0 <= bary[2]) && (bary[2] <= 1));
  }

  distance(point1, point2) {
    return Math.pow(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2), .5);
  }

  get_circumcircle(triangle, points) {
    var circumcenter = this.get_circumcenter(triangle, points);
    var radius = this.distance(circumcenter, points[triangle[0]]);
    return [circumcenter, radius];
  }

  dragPoint = e => {
    var newPoints = this.state.points.slice()
    newPoints[e.target.attrs.id] = [e.target.attrs.x, e.target.attrs.y]
    this.setState({ points: newPoints, triangles: this.generate_triangles(newPoints), circles: []});
  }

  moveMouse = e => {
    var mouse_x = e.evt.offsetX;
    var mouse_y = e.evt.offsetY;
    var containing_triangle = null;
    var i = 0
    while (containing_triangle === null && i < this.state.triangles.length) {
      if (this.is_point_in_triangle(this.state.triangles[i], this.state.points, [mouse_x, mouse_y])) {
        containing_triangle = this.state.triangles[i];
      }
      i ++;
    }
    if (containing_triangle === null) {
      this.setState({ circles: [], highlighted_triangles: []});
    } else {
      var circumcircle = this.get_circumcircle(containing_triangle, this.state.points);
      this.setState({ circles: [ circumcircle ], highlighted_triangles: [ containing_triangle ]});
    }
  }

  trianglePath(triangle) {
    var point_path = triangle.map((pointIndex) => this.state.points[pointIndex]);
    var string_point_path = point_path.map(pos => String(pos[0]) + " " + String(pos[1]));
    return "M" + string_point_path.join("L") + "z";
  }

  render() {
    return (
      <span>
        <Stage width={ this.props.width } height={ this.props.height } onMouseMove={ this.moveMouse }>
          <Layer>
            {this.state.triangles.map((triangle, index) => (
              <Path
                key={ index }
                data={this.trianglePath(triangle)}
                stroke="red"
                strokeWidth={ 2 }
                fill={ this.state.highlighted_triangles.includes(triangle) ? "rgba(255, 0, 0, .25)": "" }
              />
            ))}
            {this.state.circles.map((circle, index) => (
              <Circle
                key={ index }
                x={ circle[0][0] }
                y={ circle[0][1] }
                radius={ circle[1] }
                fill="blue"
                opacity={ .25 }
              />
            ))}
            {this.state.circles.map((circle, index) => (
              <Circle
                key={ index }
                x={ circle[0][0] }
                y={ circle[0][1] }
                radius={ 5 }
                fill="blue"
              />
            ))}
            {this.state.points.map((point, index) => (
              <Circle
                draggable={ true }
                key={ index }
                id={ index }
                x={ point[0] }
                y={ point[1] }
                radius={ 5 }
                onDragMove = { this.dragPoint }
                onDragEnd = { this.dragPoint }
                fill="black"
              />
            ))}
          </Layer>
        </Stage>
      </span>
    )
  }
}
