import React, { Component } from "react";
import ReactDOM from "react-dom";
import MapPic from './Equirectangular_projection_SW.jpg';
import { Stage, Layer, Circle, Path, Rect} from 'react-konva';
import useImage from 'use-image';

import { all_day_crossing } from './AllDayCrossing.js';

const STARTING_DAY_NIGHT_LONG = 0;
const EARTH_ROTATIONAL_SPEED = (2 * Math.PI) / (24 * 60 * 60); // rad per sec
const EARTH_RADIUS = 6000; //miles ish
const EARTH_SPEED = EARTH_RADIUS * EARTH_ROTATIONAL_SPEED;
const DEFAULT_SPEED = EARTH_SPEED * .5;
const TIME_STEP = 40; //ms
const TIME_RATE = 1 * 60 * 60; //sec per second
const TIME_PER_FRAME = TIME_RATE * TIME_STEP * .001 // minutes per second * second per frame

const SWITCHOVER_LAT = Math.acos(DEFAULT_SPEED/EARTH_SPEED) * 180 / Math.PI;

/*
// the first very simple and recommended way:
const LionImage = () => {
  const [image] = useImage('https://konvajs.org/assets/lion.png');
  return <Image image={image} />;
};
*/
export default class MapCrossing extends React.Component {
    constructor(props) {
      super(props);
      this.image_height = 300;
      this.image_width = 600;
      this.speed = DEFAULT_SPEED;
      this.master_solution = new all_day_crossing(true, 0, this.speed, TIME_PER_FRAME, EARTH_RADIUS, EARTH_SPEED); // don't recompute without reason
      this.state = this.plan_course(true, STARTING_DAY_NIGHT_LONG);
      this.update = this.update.bind(this);
    }

    plan_course(north_to_south, day_night_long) {
        const sign = north_to_south ? 1 : -1;
        const plane_points = this.master_solution.map((point) => [sign * point[0], point[1] + day_night_long]);
        return {
            north_to_south: north_to_south,
            day_night_long: day_night_long,
            plane_points: plane_points,
            plane_index: 0,
        }
    }

    componentDidMount() {
        setInterval(this.update, TIME_STEP);
    }

    update() {
        const north_to_south = this.state.north_to_south;
        const day_night_long = this.state.day_night_long;
        const plane_index = this.state.plane_index;
        const long_update = EARTH_ROTATIONAL_SPEED * TIME_PER_FRAME * 180 / Math.PI;
        const new_day_night_long = (day_night_long - long_update + 360) % 360;
        if (plane_index >= this.state.plane_points.length - 1) {
            this.setState( this.plan_course(! north_to_south, new_day_night_long ));
        } else {
            this.setState( {
                plane_index: plane_index + 1,
                day_night_long: new_day_night_long,
            })
        }
    }

    longToX(long) {
        return (((long + 360) % 360) / 360) * this.image_width
    }

    latToY(lat) {
        return (-1*lat/180 + .5) * this.image_height;
    }

    planeX() {
        return this.longToX(this.state.plane_points[this.state.plane_index][1]);
    }

    planeY() {
        return this.latToY(this.state.plane_points[this.state.plane_index][0]);
    }

    vis_path(path) {
        const point_path = path.map(pos => [this.longToX(pos[1]), this.latToY(pos[0])]);
        const string_point_path = point_path.map((pos, i) => {
           const label = String(pos[0]) + " " + String(pos[1]);
           if (i == 0) {
               return "M" + label;
           } else if (point_path[i - 1][0] < pos[0]) {
               return "M" + label;
           } else {
               return "L" + label;
           }
        });
        return string_point_path.join("");
    }

    path_at_lat(lat) {
        const y = String(this.latToY(lat));
        return "M 0 " + y + " L " + String(this.image_width) + " " + y;
    }

    render() {
        return (
          <div style={{display: 'grid', width: this.image_width, margin: "auto"}}>
            <img src={MapPic} width={ this.image_width } height={ this.image_height }
                style={{gridRow: 1, gridColumn: 1}}></img>
            <Stage width={ this.image_width } height={ this.image_height }
              style={{gridRow: 1, gridColumn: 1}}>
              <Layer>
                <Path data={ this.path_at_lat(SWITCHOVER_LAT) } stroke="black" strokeWidth={ 2 } dash={ [10, 10] }/>
                <Path data={ this.path_at_lat(-1*SWITCHOVER_LAT) } stroke="black" strokeWidth={ 2 } dash={ [10, 10] }/>
                <Path data={ this.vis_path(this.state.plane_points) } stroke="red" strokeWidth={ 2 }/>
                <Circle
                    x={ this.planeX() }
                    y={ this.planeY() }
                    radius={ 5 }
                    fill="blue"
                  />
                <Rect
                    x={ this.longToX(this.state.day_night_long - 180) - this.image_width }
                    y={ 0 }
                    height={ this.image_height }
                    width={ this.image_width * .5 }
                    fill={ 'black' }
                    opacity={ .5 }
                  />
                <Rect
                    x={ this.longToX(this.state.day_night_long - 180)}
                    y={ 0 }
                    height={ this.image_height }
                    width={ this.image_width * .5 }
                    fill={ 'black' }
                    opacity={ .5 }
                  />
              </Layer>
            </Stage>
          </div>
        )
    }
}