import React, { useState } from "react"
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

const DEFAULT_WINS = 5;
const DEFAULT_GAMES = 10;
const DEFAULT_BETA = 3;
const TIME_DELAY = 1000; // in milliseconds

function get_p(wins, games, beta) {
    if (games === 0 && beta === 0) {
        return .5;
    }
    const p = (wins + beta)/(games + 2*beta);
    return p;
}

function fix_value(value, min, round) {
    const bounded_value = Math.max(Number(value), min);
    if (round) {
        return Math.floor(bounded_value);
    }
    return bounded_value;
}

function is_valid(wins, games, beta) {
    return wins > 0 && wins === Math.floor(wins) &&
            wins <= games && games >= 1 && games === Math.floor(games) &&
            beta >= 0;
}

const BetaCalculator = (props) => {
    const classes = useStyles();

    const [wins, setWins] = useState(props.wins || DEFAULT_WINS);
    const [games, setGames] = useState(props.games || DEFAULT_GAMES);
    const [beta, setBeta] = useState(props.beta || DEFAULT_BETA)
    const [p, setP] = useState(get_p(wins, games, beta));
    const [time_out, setTimeOut] = useState(null);

    const fixScores = (new_wins, new_games, new_beta) => {
        if (time_out) {
            clearTimeout(time_out);
        }
        setWins(new_wins);
        setGames(new_games);
        setBeta(new_beta);
        if (is_valid(new_wins, new_games, new_beta)) {
            const p = get_p(Number(new_wins), Number(new_games), Number(new_beta));
            setP(p)
        }

        const adjusted_wins = fix_value(new_wins, 0, true);
        const adjusted_games = fix_value(new_games, adjusted_wins, true);
        const adjusted_beta = fix_value(new_beta, 0, false)
        const adjusted_p = get_p(adjusted_wins, adjusted_games, adjusted_beta)
        const new_time_out = setTimeout(() => {
            setWins(adjusted_wins); 
            setGames(adjusted_games);
            setBeta(adjusted_beta);
            setP(adjusted_p);
        }, TIME_DELAY);
        setTimeOut(new_time_out);
    }

    return (
      <div style={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        paddingBottom: 2,
        maxWidth: 690,
        margin: 'auto' }}>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="wins"
            label="Number of Wins"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="filled"
            onChange={ event => fixScores(event.target.value, games, beta) }
            value={ wins }
          />
          <TextField
            id="games"
            label="Number of Games"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="filled"
            onChange={ event => fixScores(wins, event.target.value, beta) }
            value={ games }
          />
          <TextField
            id="beta"
            label="Beta"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="filled"
            onChange={ event => fixScores(wins, games, event.target.value) }
            value={ beta }
          />
        </form>
        <div style={{ textAlign: 'center' }}>
          <p>Gives a win probability of <b>{p.toFixed(3)}</b></p>
        </div>
      </div>
    );
  }

export default BetaCalculator
