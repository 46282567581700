import React, { Component } from "react";
import ReactDOM from "react-dom";
import MapPic from './Equirectangular_projection_SW.jpg';
import { Stage, Layer, Circle, Path, Rect, Arrow} from 'react-konva';
import { Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, NoSsr } from '@material-ui/core';
import useImage from 'use-image';
import ArrowKeysReact from 'arrow-keys-react';

import { get_next_position } from './AllDayCrossing.js';

import "./MapCrossingGame.css"

const STARTING_DAY_NIGHT_LONG = 90;
const EARTH_ROTATIONAL_SPEED = (2 * Math.PI) / (24 * 60 * 60); // rad per sec
const EARTH_RADIUS = 6000; //miles ish
const EARTH_SPEED = EARTH_RADIUS * EARTH_ROTATIONAL_SPEED;
const OPTIMAL_SPEED = EARTH_SPEED * .5;
const TIME_STEP = 40; //ms
const TIME_RATE = 1 * 60 * 60; //sec per second
const TIME_PER_FRAME = TIME_RATE * TIME_STEP * .001 // minutes per second * second per frame
const SPEED_MULTIPLIER_UPDATE = 1 / TIME_STEP;

const ANGLE_INCREMENT = .07;

const LEVELS = [OPTIMAL_SPEED * 1.6, OPTIMAL_SPEED * 1.2, OPTIMAL_SPEED * 1.05];

const COUNTDOWN_TIME = 4;

export default class MapCrossing extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.image_height = 350;
    this.image_width = 700;
    this.state = {
      running: false,
      in_countdown: false,
      countdown_time_remaining: COUNTDOWN_TIME,
      finished: false,
      won: false,
      level: 0, // 0, 1, or 2
      day_night_long: STARTING_DAY_NIGHT_LONG,
      plane_lat: 85,
      plane_long: 180,
      plane_angle: Math.PI / 2, // straight down
      plane_speed: LEVELS[0],
      speed_multiplier: 1,
    }
    ArrowKeysReact.config({
      left: () => {
        const desired_angle = 0;
        this.rotate_plane(-1 * ANGLE_INCREMENT);
      },
      right: () => {
        const desired_angle = Math.PI;
        this.rotate_plane(ANGLE_INCREMENT);
      },
      up: () => {
      },
      down: () => {
      },
    });
    this.update = this.update.bind(this);
  }

  rotate_plane(angle_increment) {
    const angle = this.state.plane_angle;
    this.setState({
      plane_angle: (angle + angle_increment + 2 * Math.PI) % (2 * Math.PI)
    });
  }

  start() {
    this.setState({
      running: true,
      in_countdown: true,
      countdown_time_remaining: COUNTDOWN_TIME,
      finished: false,
      won: false,
      day_night_long: STARTING_DAY_NIGHT_LONG,
      plane_lat: 85,
      plane_long: 180,
      plane_angle: Math.PI/2,
      speed_multiplier: 0,
    });
    this.timer = setInterval(() => {
      this.decrementTimeRemaining();
    }, 1000);
  }

  decrementTimeRemaining() {
    if (this.state.countdown_time_remaining > 0) {
      this.setState({
        in_countdown: this.state.countdown_time_remaining > 1,
        countdown_time_remaining: this.state.countdown_time_remaining - 1
      });
    } else {
      clearInterval(this.timer);
    }
  };

  componentDidMount() {
    setInterval(this.update, TIME_STEP);
  }

  update() {
    if (this.state.running && ! this.state.finished && ! this.state.in_countdown) {
      const day_night_long = this.state.day_night_long;
      const long_update = EARTH_ROTATIONAL_SPEED * TIME_PER_FRAME * 180 / Math.PI;
      const new_day_night_long = (day_night_long - long_update + 360) % 360;
      const plane_update = this.next_plane_loc(this.state.speed_multiplier);
      this.setState( Object.assign(plane_update, 
      {
        day_night_long: new_day_night_long,
      }));
    }
  }

  next_plane_loc(speed_multiplier) {
    const next_position = get_next_position(
      this.state.plane_lat,
      this.state.plane_long,
      this.state.plane_angle,
      this.state.plane_speed * speed_multiplier,
      TIME_PER_FRAME,
      EARTH_RADIUS);
    var plane_angle = this.state.plane_angle;
    let finished = this.state.finished;
    let won = this.state.won;
    const next_speed_multiplier = Math.min(1, this.state.speed_multiplier + SPEED_MULTIPLIER_UPDATE);
    // add to fix negative modulo bug
    if ((next_position[1] - this.state.day_night_long + 360*2) % 360 > 180) {
      finished = true;
      won = false;
    }
    if (next_position[0] > 90) {
      next_position[0] = 180 - next_position[0];
      next_position[1] = (180 + next_position[1]) % 360;
      plane_angle += Math.PI;
    } else if (next_position[0] < -90) {
      next_position[0] = -180 - next_position[0]
      next_position[1] = (180 + next_position[1]) % 360;
      plane_angle += Math.PI;
      finished = true;
      won = true;
    }
    return {
      plane_lat: next_position[0],
      plane_long: next_position[1],
      plane_angle: plane_angle,
      finished: finished,
      won: won,
      speed_multiplier: next_speed_multiplier
    }
  }

  longToX(long) {
    return (((long + 360) % 360) / 360) * this.image_width
  }

  latToY(lat) {
    return (-1*lat/180 + .5) * this.image_height;
  }

  setLevel(new_level) {
    this.setState({
      level: parseInt(new_level),
      plane_speed: LEVELS[new_level],
    })
  }

  render() {
    const plane_x = this.longToX(this.state.plane_long);
    const plane_y = this.latToY(this.state.plane_lat);
    const next_plane_loc = this.next_plane_loc(1);
    const next_plane_x = this.longToX(next_plane_loc.plane_long);
    const next_plane_y = this.latToY(next_plane_loc.plane_lat);
    const arrow_length = 20 * Math.sqrt(Math.pow(plane_x - next_plane_x, 2) + Math.pow(plane_y - next_plane_y, 2));
    let message = this.state.finished ? (this.state.won ? <>You Won!</> : <> Night caught you - give it another go!</>) : <>Fly to the South and don't let night catch you.</>;
    let popup = <></>;
    if (! this.state.running || this.state.finished) {
      popup = <>
        <div className="popup_back" style={{width: this.image_width, height: this.image_height}}/>
        <div className="popup_window">
          <span className="popup_text">
            {message}
          </span>
          <NoSsr>
          <FormControl component="fieldset">
          <FormLabel component="legend">Difficulty</FormLabel>
          <RadioGroup
            className="popup_radio"
            aria-label="Difficulty"
            name="Difficulty"
            value={ this.state.level }
            onChange={ e => this.setLevel(e.target.value) }
            row>
            <FormControlLabel className="popup_label" value={ 0 } control={<Radio />} label="Easy" />
            <FormControlLabel className="popup_label" value={ 1 } control={<Radio />} label="Medium" />
            <FormControlLabel className="popup_label" value={ 2 } control={<Radio />} label="Hard" />
          </RadioGroup>
          <Button variant="contained" className="popup_play_button" onClick={() => { this.start(); this.myComponent.focus() }}>
            {this.state.finished ? "GO AGAIN!" : "START!"}
          </Button>
        </FormControl>
        </NoSsr>
        </div>
      </>;
    }
    let timer = <></>;
    if (this.state.in_countdown) {
      timer = <>
        <div className="countdown-timer">
          <div className="countdown-timer__circle">
            <svg>
              <circle
                r="38"
                cx="40"
                cy="40"
                style={{
                  animation: `countdown-animation ${COUNTDOWN_TIME}s linear`
                }}
              />
            </svg>
          </div>
          <div className="countdown-timer__text">
            {this.state.countdown_time_remaining > 1 ? this.state.countdown_time_remaining - 1 : 'GO!'}
          </div>
        </div>
      </>;
    }
    return (
      <div style={{position: 'relative', width: this.image_width, height: this.image_height + 10, margin: "auto"}} {...ArrowKeysReact.events} tabIndex="1" ref={(ip) => this.myComponent = ip}>
        <img src={MapPic} width={ this.image_width } height={ this.image_height }
          style={{position: 'absolute'}}></img>
        <Stage width={ this.image_width } height={ this.image_height }
          style={{position: 'absolute'}}>
          <Layer>
            <Circle
              x={ plane_x }
              y={ plane_y }
              radius={ 5 }
              fill="red"
              shadowColor="black"
              shadowBlur={ 5 }
              shadowOffset= {{ x: 3, y: 3 }}
              shadowOpacity={ .5 }
            />
            <Arrow
              points={[
                plane_x,
                plane_y,
                plane_x - Math.cos(this.state.plane_angle) * arrow_length,
                plane_y + Math.sin(this.state.plane_angle) * arrow_length,
              ]}
              fill={ "red" }
              stroke={ "red" }
              strokeWidth={ 2 }
              pointerLength={ 5 }
              pointerWidth={ 5 }
              shadowColor="black"
              shadowBlur={ 5 }
              shadowOffset= {{ x: 3, y: 3 }}
              shadowOpacity={ .5 }
            />
            <Rect
              x={ this.longToX(this.state.day_night_long - 180) - this.image_width }
              y={ 0 }
              height={ this.image_height }
              width={ this.image_width * .5 }
              fill={ 'black' }
              opacity={ .5 }
            />
            <Rect
              x={ this.longToX(this.state.day_night_long - 180)}
              y={ 0 }
              height={ this.image_height }
              width={ this.image_width * .5 }
              fill={ 'black' }
              opacity={ .5 }
            />
          </Layer>
        </Stage>
        {popup}
        {timer}
      </div>
    )
  }
}