import Globe from "./Globe";
import React from "react"
import * as THREE from "three";
import { all_day_crossing } from './AllDayCrossing.js';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, NoSsr } from '@material-ui/core';

const TIME_RATE = 1 * 60 * 60; // seconds per second

class GlobeCrossingBase extends Globe {
    constructor( props ) {
        super(props);
    }

    add_childclass_objects( scene ) {
        this.master_solution = new all_day_crossing(
            true,
            0,
            this.rotational_vel * this.radius * .5,
            this.time_per_frame,
            this.radius,
            this.rotational_vel * this.radius); // don't recompute without reason
        this.setState(this.plan_course(true));

        const plane_material = new THREE.MeshPhongMaterial( { color: 0x0000ff } );
        const plane_geometry = new THREE.SphereGeometry( 10, 5, 5 );
        this.plane = new THREE.Mesh( plane_geometry, plane_material );
        scene.add( this.plane );

        const path_material = new THREE.MeshPhongMaterial( { color: 0xff0000 } );
        const path_geometry = this.get_path_geometry(this.master_solution);
        this.path = new THREE.Mesh( path_geometry, path_material);
        scene.add( this.path )
    }

    get_path_geometry(lat_long_points) {
        const x_y_z = lat_long_points.map(p => this.lat_long_to_x_y_z(p));
        const points = x_y_z.map(p => new THREE.Vector3(p[0], p[1], p[2]));
        return new THREE.TubeGeometry(new THREE.CatmullRomCurve3(points), 40, 2);
    }

    move_plane() {
        const x_y_z = this.lat_long_to_x_y_z(this.state.plane_points[this.state.plane_index])
        this.plane.position.x = x_y_z[0];
        this.plane.position.y = x_y_z[1];
        this.plane.position.z = x_y_z[2];
    }
  
    plan_course(north_to_south) {
        const day_night_long_unmod = 180 / Math.PI * (this.state.sun_angle - this.state.globe_angle) - 90;
        const day_night_long = ((day_night_long_unmod % 360) + 360) % 360
        const sign = north_to_south ? 1 : -1;
        const plane_points = this.master_solution.map((point) => [ sign*point[0], point[1] + day_night_long]);
        return {
            north_to_south: north_to_south,
            plane_points: plane_points,
            plane_index: 0,
        }
    }
    
    update_childclass_objects() {
        const north_to_south = this.state.north_to_south;
        const plane_index = this.state.plane_index;
        if (plane_index >= this.state.plane_points.length - 1) {
            this.setState( this.plan_course(! north_to_south));
        } else {
            this.setState( {
                plane_index: plane_index + 1,
            })
        }
        this.move_plane();
        this.path.geometry = this.get_path_geometry(this.state.plane_points);
    }
}

const GlobeCrossing = (props) => {
    const [reference, setReference] = React.useState('earth')
    return (
      <div style={{width: "400px", margin: "auto"}}>
        <GlobeCrossingBase
            rotate_earth={true}
            earth_frame={reference === 'earth'}
            axial_tilt={0}
            time_rate={TIME_RATE}
            show_arctic_circles={false}
            circle_lats={[-60, 60]}
            ambient_intensity={1}
        />
        <NoSsr>
        <FormControl component="fieldset">
          <FormLabel component="legend">Frame of Reference</FormLabel>
          <RadioGroup aria-label="Season" name="Season" value={ reference } onChange={ e => setReference(e.target.value) }>
            <FormControlLabel value={ 'earth' } control={<Radio />} label="Earth" />
            <FormControlLabel value={ 'space' } control={<Radio />} label="Space" />
          </RadioGroup>
        </FormControl>
        </NoSsr>
      </div>
    );
  }

export default GlobeCrossing