import React from "react"

const FootnoteReference = ({ label }) => {
  return <sup id={ `fnref-${label}` }>
            <a href= { `#fn-${label}` } className="footnote-ref">
                { label }
            </a>
        </sup>
}

export default FootnoteReference