import React from "react"
import "katex/dist/katex.min.css";
import renderMathInElement from "katex/dist/contrib/auto-render.js";
import { string_to_id } from '../utils/GetID.js';

export default class Caption extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    renderMathInElement(this.myRef.current,
      {delimiters: [
        {left: "$$", right: "$$", display: true},
        {left: "$", right: "$", display: false},
      ]});
  }

  render() {
    return (
      <>
      <div ref={this.myRef} style={{position: 'relative'}}>
        <a id={ string_to_id(this.props.label) }
          style={{
            display: 'block',
            position: 'relative',
            top: '-450px',
            visibility: 'hidden',}}
        />
        <div style={{textAlign: 'justify'}}>
          <font size = "3">
            <strong>
              { `${this.props.label}:` }
            </strong>
            { this.props.children }
          </font>
        </div>
      </div>
      <br></br>
      </>
    );
  }
}
