import React from "react"

function getHeader(header) {
  return (
    <thead>
    <tr>
      {header.map((value, i) => (
        <th style={i === 0 ? {}: {textAlign: "center"} } key={ i }>{value}</th>
      ))}
    </tr>
    </thead>
  );
}

function getDataRow(row, index) {
  return (
    <tr key={ index }>
      {row.map((value, i) => (
        <td style={i === 0 ? {}: {textAlign: "center"} } key={ i }>{value}</td>
      ))}
    </tr>
  );
}

function getDataRows(rows) {
  return (
    <tbody>
      {rows.map((row, i) => getDataRow(row, i))}
    </tbody>
  )
}

const ScrollableTable = (props) => {
  const data_obj = JSON.parse(props.data);
  const header = data_obj[0];
  const rows = data_obj.slice(1);
  return (
    <div style={{overflow: 'scroll', height: props.height}}>
    <table>
    {getHeader(header)}
    {getDataRows(rows)}
    </table>
    </div>
  );
}

export default ScrollableTable