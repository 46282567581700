import { graphql, useStaticQuery } from "gatsby"
import React from "react";
import { render } from "react-dom";

import "./RandomSnowleopard.css"
import SnowLeopardGif from './snowleopard_walking_edited.gif'

const DEFAULT_ODDS = .1; // chance per second
const WALK_TIME = 20; //seconds

export default class RandomSnowleopard extends React.Component {
    constructor(props) {
        super(props);
        this.odds = props.odds == null ? DEFAULT_ODDS : props.odds;
        this.state = {
          visible: false,
          last_start_time: -10*WALK_TIME,
          current_time: 0,
        };
        this.run = this.run.bind(this);
    }

    componentDidMount() {
        this.intervalId = setInterval(this.run.bind(this), 1000);
    }

    run() {
        const time = this.state.current_time + 1;
        const last_time = this.state.last_start_time;
        if (! this.state.visible && last_time + WALK_TIME*2 < time) {
          if (Math.random() < this.odds) {
            this.setState({
              visible: true,
              last_start_time: time,
              current_time: time,
            })
          } else {
            this.setState({
              current_time: time,
            })
          }
        }
        else {
          this.setState({
            visible: last_time + WALK_TIME > time,
            current_time: time,
          })
        }
    }

    render() {
        return (
        <>
        <div className='snowleopard_div' 
            style={this.state.visible ? {transform: 'translate(-2000px, 500px)'} : {}}>
            <img className='snowleopard_gif' src={SnowLeopardGif}
            style={this.state.visible ? {}: {opacity: 0}}></img>
        </div>
        </>
        );
    }
}
    