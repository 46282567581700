import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as THREE from "three";
import MapPic from './Equirectangular_projection_SW.jpg';

import VisibilityDetector from "../utils/VisibilityDetector"


const OrbitControls = require('three-orbit-controls')(THREE);

const TIME_STEP = 30; // frame rate in ms
const TIME_RATE = 1 * 60 * 60; // seconds per second
const ROTATIONAL_VEL = (2 * Math.PI) / (24 * 60 * 60) // rad per second

const WIDTH = 400;
const HEIGHT = 400;

const VIEW_ANGLE = 45;
const ASPECT = WIDTH / HEIGHT;
const NEAR = 0.1;
const FAR = 10000;

const RADIUS = 200;
const SEGMENTS = 50;
const RINGS = 50;

const TRUE_TILT = 23.5;
// globe is at 0, 0, 0
// camera is at distance 1000

export default class Globe extends React.Component {
  constructor(props) {
    super(props);
    this.transparent = props.transparent == undefined ? false : props.transparent;
    this.opacity = props.opacity == undefined ? .75 : props.opacity;
    this.show_axis = props.show_axis == undefined ? true: props.show_axis;
    this.show_arctic_circles = props.show_arctic_circles == undefined ? true: props.show_arctic_circles;
    this.time_rate = props.time_rate == undefined ? TIME_RATE : props.time_rate;
    this.rotational_vel = props.rotational_vel == undefined ? ROTATIONAL_VEL: props.rotational_vel;
    this.camera_angle = props.camera_angle == undefined ? 0 : props.camera_angle;
    this.circle_lats = props.circle_lats == undefined ? []: props.circle_lats;
    this.circle_color = props.circle_color == undefined ? 0xffffff: props.circle_color;
    this.can_zoom = props.can_zoom == undefined ? true : props.can_zoom;
    this.ambient_intensity = props.ambient_intensity == undefined ? .3 : props.ambient_intensity;

    this.radius = RADIUS;
    this.time_per_frame = this.time_rate * TIME_STEP * .001;
    this.state = {
        globe_angle: 0,
        sun_angle: 0,
        current_tilt: props.axial_tilt,
        is_visible: false,
    };
    this.update = this.update.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
  }

  componentDidMount() {
    var scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
    var renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setSize( WIDTH, HEIGHT );
    renderer.setClearColor( 0x000000, .5 );

    camera.position.set( 0, 1000 * Math.sin(this.camera_angle), 1000 * Math.cos(this.camera_angle) );
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild( renderer.domElement );
    var texture = new THREE.TextureLoader().load( MapPic );
    var geometry = new THREE.SphereGeometry( this.radius, SEGMENTS, RINGS );
    if (this.transparent) {
      const back_material = new THREE.MeshBasicMaterial( {
        map: texture,
        transparent: true,
        opacity: this.opacity,
        side: THREE.BackSide,
      });
      const front_material = new THREE.MeshBasicMaterial( {
        map: texture,
        transparent: true,
        opacity: this.opacity,
        side: THREE.FrontSide,
      });
      this.back_globe = new THREE.Mesh( geometry, back_material );
      this.front_globe = new THREE.Mesh( geometry, front_material );
      scene.add(this.back_globe);
      scene.add(this.front_globe);
    } else {
      var material = new THREE.MeshPhongMaterial( { 
        map: texture,
      } );
      this.globe = new THREE.Mesh( geometry, material );
      scene.add( this.globe );
    }

    this.sun = new THREE.DirectionalLight(0xFFFFFF);
    this.sun.position.x = 0;
    this.sun.position.y = Math.tan(this.props.axial_tilt);
    this.sun.position.z = 1;
    scene.add( this.sun );

    var light = new THREE.AmbientLight( 0x404040, this.ambient_intensity ); // soft white light
    scene.add( light );

    if (this.show_axis) {
      const axis_height = this.radius*1.125
      var axis_geometry = new THREE.CylinderGeometry( 5, 5, axis_height*2, 20 );
      var axis_material = new THREE.MeshPhongMaterial( {color: 0x000000} );
      var axis = new THREE.Mesh( axis_geometry, axis_material );
      scene.add( axis );
    }

    if (this.show_arctic_circles) {
      this.add_circle(scene, 90 - TRUE_TILT, 0xffff00);
      this.add_circle(scene, TRUE_TILT - 90, 0xffff00);
    }
    
    this.circle_lats.map(lat => this.add_circle(scene, lat, this.circle_color))

    this.add_childclass_objects( scene );

    var controls = new OrbitControls( camera, renderer.domElement );
    controls.minDistance = this.radius * 1.5;
    controls.maxDistance = this.radius * 10;
    controls.enablePan = false;
    controls.enableZoom = this.can_zoom;

    var animate = function () {
      requestAnimationFrame( animate );
      controls.update();
      renderer.render( scene, camera );
    };
    animate();
  }

  shouldComponentUpdate() {
    return this.state.is_visible;
  }

  setVisibility(visibility) {
    const prior_visibility = this.state.is_visible;
    if (visibility && ! prior_visibility) {
      const interval_id = setInterval(this.update, TIME_STEP);
      this.setState({ is_visible: true, interval_id })
    } else if (! visibility && prior_visibility) {
      clearInterval(this.state.interval_id);
      this.setState({ is_visible: false })
    }
  }

  add_circle(scene, latitude, color) {
    const angle = latitude * Math.PI / 180
    const axis_rad = this.radius * Math.cos(angle);
    const circ_geom = new THREE.CylinderGeometry( axis_rad, axis_rad, 2, SEGMENTS );
    const circ_material = new THREE.MeshPhongMaterial( { color, side: THREE.DoubleSide } );
    const circ = new THREE.Mesh( circ_geom, circ_material );
    circ.position.y = Math.sin(angle) * this.radius;
    scene.add( circ );
  }

  update() {
    if (this.props.rotate_earth) {
      this.rotate_globe();
    }
    this.update_childclass_objects();
  }

  add_childclass_objects( scene ) {
    return;
  }

  update_childclass_objects() {
    return;
  }

  rotate_globe() {
    if (this.props.axial_tilt != this.state.current_tilt) {
      const current_tilt = this.state.current_tilt
      const diff_tilt = this.props.axial_tilt - this.state.current_tilt
      const delta_tilt_mag = TIME_STEP * .001 * 1.5;
      const delta_tilt = Math.sign(diff_tilt) * Math.min(delta_tilt_mag, Math.abs(diff_tilt));
      const new_tilt = current_tilt + delta_tilt;
      this.sun.position.y = Math.tan(new_tilt);
      this.setState( {current_tilt: new_tilt} )
    }
    if (this.props.earth_frame) {
      const new_theta = this.state.sun_angle - this.time_per_frame * this.rotational_vel;
      this.sun.position.x = Math.sin(new_theta);
      this.sun.position.z = Math.cos(new_theta);
      this.setState({ sun_angle: new_theta })
    } else {
      const new_theta = this.state.globe_angle + this.time_per_frame * this.rotational_vel;
      this.true_rotate_globe(new_theta);
      this.setState({ globe_angle: new_theta })  
    }
  }

  true_rotate_globe(theta) {
    if (this.transparent) {
      this.front_globe.rotation.y = theta;
      this.back_globe.rotation.y = theta;
    } else {
      this.globe.rotation.y = theta;
    }
  }

  lat_long_to_x_y_z(lat_long) {
    const lat = lat_long[0]
    const long = lat_long[1]
    const angle = this.state.globe_angle + long * Math.PI / 180;
    const y = Math.sin(lat * Math.PI / 180);
    const scale = this.radius * Math.sqrt(1 - y*y);
    const x = scale * Math.sin(angle);
    const z = scale * Math.cos(angle);
    return [x, y * this.radius, z];
  }

  render() {
    return (
      <div ref={ref => (this.mount = ref)} style={{position: 'relative'}}>
        <VisibilityDetector isVisible={this.setVisibility}></VisibilityDetector>
      </div>
    )
  }
}
