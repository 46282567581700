import React, { useState } from "react"
import Globe from "./Globe"
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, NoSsr } from '@material-ui/core';

const DEFAULT_TILT = 23.5 * Math.PI / 180;
const DEFAULT_FRAME = true;

const TILT_MAPPING = new Object();
TILT_MAPPING['S'] = DEFAULT_TILT;
TILT_MAPPING['E'] = 0;
TILT_MAPPING['W'] = -1*DEFAULT_TILT;

const REFERENCE_MAPPING = new Object();
REFERENCE_MAPPING['earth'] = true;
REFERENCE_MAPPING['space'] = false;

const Seasons = (props) => {
    const [tilt, setTilt] = useState(DEFAULT_TILT);
    const [earthFrame, setEarthFrame] = useState(DEFAULT_FRAME)
    const [season, setSeason] = React.useState('S');
    const [reference, setReference] = React.useState('earth')

    const handleSeasonChange = (event) => {
      setSeason(event.target.value);
      setTilt(TILT_MAPPING[event.target.value]);
    };
    const handleReferenceChange = (event) => {
      setReference(event.target.value);
      setEarthFrame(REFERENCE_MAPPING[event.target.value]);
    };

    return (
      <div style={{margin: "auto", width: "400px"}}>
        <Globe
          rotate_earth={ true }
          earth_frame={ earthFrame }
          axial_tilt={ tilt }
          can_zoom={ false }>
        </Globe>
        <NoSsr>
          <FormControl component="fieldset">
            <FormLabel component="legend">Season</FormLabel>
            <RadioGroup aria-label="Season" name="Season" value={ season } onChange={ handleSeasonChange }>
              <FormControlLabel value={ 'S' } control={<Radio />} label="Northern Summer" />
              <FormControlLabel value={ 'E' } control={<Radio />} label="Equinox" />
              <FormControlLabel value={ 'W' } control={<Radio />} label="Northern Winter" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Frame of Reference</FormLabel>
            <RadioGroup aria-label="Season" name="Season" value={ reference } onChange={ handleReferenceChange }>
              <FormControlLabel value={ 'earth' } control={<Radio />} label="Earth" />
              <FormControlLabel value={ 'space' } control={<Radio />} label="Space" />
            </RadioGroup>
          </FormControl>
        </NoSsr>
      </div>
    );
  }

export default Seasons