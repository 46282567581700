function deg_to_rad(degrees) {
    return degrees * Math.PI / 180;
}

// function that given longitude of day-night, direction of crossing and time step, returns points on crossing
export function all_day_crossing(north_to_south, day_night_long, speed, time_step, earth_radius, earth_speed) {
    var lat = (north_to_south ? 1: -1) * 90;
    var long = day_night_long;
    var terminated = false;
    const points = [];
    while (! terminated) {
        points.push([lat, long]);
        const new_points = next_move(north_to_south, lat, long, speed, time_step, earth_radius, earth_speed);
        const new_lat = new_points[0];
        const new_long = new_points[1];
        if (new_lat*(north_to_south ? -1: 1) > 90 || isNaN(new_lat)) { // going wrong way
            terminated = true;
        } else {
            lat = new_lat;
            long = new_long;
        }
        if (points.length > 2000) {
            terminated = true;
        }
    }
    return points;
}

function next_move(north_to_south, lat, long, speed, time_step, earth_radius, earth_speed) {
    const angle = (north_to_south ? 1: -1) * get_heading(lat, speed, earth_speed);
    return get_next_position(lat, long, angle, speed, time_step, earth_radius);
}

export function get_next_position(lat, long, angle, speed, time_step, earth_radius) {
    // using angle in radians off of west towards south
    const western_velocity = Math.cos(angle) * speed;
    const southern_velocity = Math.sin(angle) * speed;
    const western_distance = western_velocity * time_step;
    const southern_distance = southern_velocity * time_step;
    const long_update = -180 / Math.PI * western_distance / (earth_radius * Math.cos(deg_to_rad(lat)));
    const lat_update = -180 / Math.PI * southern_distance / (earth_radius);
    return [lat + lat_update, (long + long_update + 360) % 360];
}

function get_heading(lat, speed, earth_speed) {
    const local_planet_speed = earth_speed * Math.cos(deg_to_rad(lat));
    if (local_planet_speed <= speed) {
        return Math.acos(local_planet_speed/speed); // I love the symmetry of these cases
    } else {
        return Math.acos(speed/local_planet_speed);
    }
}